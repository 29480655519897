
import { getStore } from "@/store/getStore";
import { Component, Vue } from "vue-property-decorator";
import { CardsActionTypes } from "@/store/modules/cards/actions/types";
import SelectProjectsMultiple from "@/components/select/projectsMultiply/SelectProjectsMultiple.vue";
import SelectMultiple from "@/components/select/selectMultiple/SelectMultiple.vue";
import SelectProjectChecklist from "@/components/select/selectProjectChecklist/SelectProjectChecklist.vue";
import DateFilter from "@/components/lib/dateFilter/DateFilter.vue";
import { CardModel, CardsListFilterModel } from "@/services/types/cards";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import { buildExcelFromCardsList } from "@/services/cards2excel";
import { CardsMutationTypes } from "@/store/modules/cards/mutations/types";
import { NameIdModel } from "@/services/types/entities/base";
import { DateRange } from "@/services/types/utility";
import apiClient from "@/services/api/apiClient";
import DownloadCardsExcelReportButton from "@/features/calls-excel-report/DownloadCardsExcelReportButton.vue";

type ProjectChecklistSelectValueType = {
  project: number;
  checklist: number;
} | null;

@Component({
  components: {
    DownloadCardsExcelReportButton,
    ActionButton,
    DateFilter,
    SelectProjectChecklist,
    SelectMultiple,
    SelectProjectsMultiple,
  },
  watch: {
    projectChecklistSelectValue:
      "updateManagersListOnSelectedProjectChecklistChange",
  },
})
export default class CardsListGridFilter extends Vue {
  projectChecklistSelectValue: ProjectChecklistSelectValueType = null;
  managerSelectValue: NameIdModel[] = [];
  startedAtDates: DateRange = {
    from: null,
    to: null,
  };
  estimatedAtDates: DateRange = {
    from: null,
    to: null,
  };

  get projectChecklistSelectOptions() {
    return getStore().getters.getCardsProjectsList;
  }

  get managerSelectOptions() {
    return getStore().getters.getCardsManagersList;
  }

  handleProjectChecklistSelect(value: { project: number; checklist: number }) {
    this.projectChecklistSelectValue = value;
  }

  handleManagerSelect(value: NameIdModel[]) {
    this.managerSelectValue = value;
  }

  handleStartedAtDatesFilterChange(dates: DateRange) {
    this.startedAtDates = dates;
  }

  handleEstimatedAtDatesFilterChange(dates: DateRange) {
    this.estimatedAtDates = dates;
  }

  updateManagersListOnSelectedProjectChecklistChange(
    value: ProjectChecklistSelectValueType
  ) {
    if (!(value && value.project)) {
      getStore().commit(CardsMutationTypes.SET_CARDS_LIST_MANAGERS_LIST, []);
      return;
    }
    getStore().dispatch(
      CardsActionTypes.SET_CARDS_LIST_MANAGERS_LIST,
      value.project
    );
  }

  async handleApplyBtnClick() {
    this.applyFilters();
    await this.fetchCards();
  }

  async handleDownloadReportClick() {
    await buildExcelFromCardsList(
      this.getExcelFileName() || "cards",
      getStore().getters.getCardsList
    );
  }

  async mounted() {
    await getStore().dispatch(
      CardsActionTypes.SET_CARDS_LIST_PROJECTS_LIST,
      null
    );
  }

  private applyFilters() {
    getStore().dispatch(
      CardsActionTypes.SET_CARDS_LIST_FILTER,
      this.getFilterModel()
    );
  }

  private fetchCards() {
    return getStore().dispatch(CardsActionTypes.SET_CARDS_LIST, null);
  }

  private getExcelFileName(): string | undefined {
    if (
      !this.projectChecklistSelectValue ||
      !this.projectChecklistSelectOptions.length
    )
      return undefined;

    const p = this.projectChecklistSelectOptions.find(
      (p) => p.id === this.projectChecklistSelectValue.project
    );
    if (!p) return undefined;

    const c = p.checklists.find(
      (c) => c.id === this.projectChecklistSelectValue.checklist
    );
    if (!c) return undefined;

    return `Ectem - ${p.name} (${c.name})`;
  }

  private getFilterModel(): CardsListFilterModel {
    return {
      projects: this.projectChecklistSelectValue
        ? [this.projectChecklistSelectValue.project]
        : null,
      checklists: this.projectChecklistSelectValue
        ? [this.projectChecklistSelectValue.checklist]
        : null,
      managers: this.managerSelectValue.map((m) => m.id),
      started: {
        from: this.startedAtDates.from || null,
        to: this.startedAtDates.to || null,
      },
      estimated: {
        from: this.estimatedAtDates.from || null,
        to: this.estimatedAtDates.to || null,
      },
    };
  }
}
